<template>
<div>
     <div class="modal" v-bind:class="{ 'is-active': selectedService }" v-if="selectedService">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirmer l'iscription</p>
          <button class="delete" aria-label="close" @click="cancelInscription()"></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          Êtes vous bien sûr de vouloir vous inscrire au service suivant ?
           <p class="selected-service">{{selectedService.dateConverted.format("dddd Do MMMM YYYY - HH:mm") }}</p>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="inscription(selectedService)">Confirmer</button>
          <button class="button" @click="cancelInscription()">Annuler</button>
        </footer>
      </div>
    </div>

  <section class="app-main">



    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Inscription à des services</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Inscription à des services</span>
            </li>
          </ol>
        </div>
      </div>
      <div v-if="!isVolant && mesinfos.abcdCounter >= 0">
        <p>
        Vous pouvez programmer des services anticipés (vacations) en vous inscrivant ici.
        </p>
        <p>
          Ces services anticipés épargnés (si >= 2) permettent de demander un congé anticipé en contactant le BDM.
        </p>
        <a href="https://membres.cooplalouve.fr/le-conge-anticipe-ou-lepargne-de-vacations-par-les-equipes-abcd/"><div>Lire cet article pour en savoir plus.</div></a>
      </div>
      <br/>
      <div v-if="isVolant || (!isVolant && mesinfos.abcdCounter >= 0)" class="animated">
        <div class="columns">
          <div class="column">
            <label class="label has-text-weight-semibold">Jours</label>
            <multiselect
              v-model="joursSelected"
              tag-placeholder="Selection des jours"
              placeholder="Selection des jours"
              label="name"
              selectLabel
              deselectLabel
              track-by="code"
              :options="options"
              :multiple="true"
              :taggable="true"
              class="multiselect"
            ></multiselect>
          </div>
          <div class="column">
            <label class="label has-text-weight-semibold">Heures</label>
            <multiselect
              v-model="horairesSelected"
              tag-placeholder="Selection des heures"
              placeholder="Selection des heures"
              label="name"
              selectLabel
              deselectLabel
              track-by="code"
              :options="optionsHoraires"
              :multiple="true"
              :taggable="true"
              class="multiselect"
            ></multiselect>
          </div>
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <article class="tile is-child box">
              <h3 class="title is-size-3 has-text-weight-normal">Services disponibles</h3>
              <section v-if="errorAPI">
                <p
                  class="container tag is-danger is-large"
                >Désolé, information non disponible actuellement. Merci de réessayer plus tard</p>
              </section>

              <section v-else>
                <div v-if="loadingAPI">
                  <p class="title box is-size-4 has-text-weight-normal m-b-30">
                    <i class="fa fa-spinner fa-pulse fa-1x fa-fw" style="color:#ff4200"></i>
                    <span>{{message}}</span>
                  </p>
                </div>

                <section v-else class="columns is-multiline">
                  <div
                    class="column subtitle is-5"
                    v-if="Object.keys(filteredServices).length === 0"
                  >Aucun service trouvé</div>
                  <div
                    v-for="groupedService in filteredServices"
                    v-bind:key="groupedService.id"
                    class="column is-4-widescreen is-6-tablet"
                  >
                    <div class="jour-service box bm--card-equal-height">
                      <h4
                        class="date-title title is-4 has-text-weight-semibold"
                      >{{groupedService[0].startDatetime | moment("dddd Do MMMM YYYY") }}</h4>
                      <table class="table is-fullwidth is-hoverable">
                        <thead>
                          <tr>
                            <th>Créneau</th>
                            <th>Places disponibles</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="service in groupedService" v-bind:key="service.id">
                            <td>{{service.dateConverted.format("HH:mm") }}</td>
                            <td>
                              <span class="icon">
                                <i class="fa fa-lg fa-users"></i>
                              </span>
                              <span
                                style="padding-left:20px"
                              >{{service.availableSpots}}</span>
                            </td>
                            <td>
                              <button
                                v-if="!service.registered"
                                class="button is-primary is-outlined"
                                @click="confirmInscription(service)">
                                 <span class="is-hidden-mobile">Inscription</span>
                                 <i class="fa fa-sign-in is-hidden-tablet"></i>
                              </button>
                              <button disabled v-else class="button is-primary">
                                <span class="is-hidden-mobile">Déjà inscrit</span>
                                <i class="fa fa-check-circle is-hidden-tablet"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </section>
            </article>
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          L’inscription à des services anticipés est impossible car vous avez des rattrapages à faire.
        </p>
        <p>
          Présentez-vous au service de votre choix en magasin pour effectuer ce rattrapage.
        </p>
        <a href="https://membres.cooplalouve.fr/les-delais-de-rattrapages/"><div>Lire cet article pour en savoir plus.</div></a>
      </div>
    </div>

  </section>
</div>
</template>

<script>
import shiftServices from "@/services/shiftServices";
import { authComputed } from "@/store/helpers.js";
import _ from "lodash";
import Multiselect from "vue-multiselect";

export default {
  name: "Inscription",
  components: {
    Multiselect
  },
  data: function() {
    return {
      search: "",
      services: "",
      loadingAPI: true,
      errorAPI: false,
      message: 'Chargement en cours ...',
      jours: [],
      heures: [],
      mesinfos: "",
      isVolant: false,
      messervices: "",
      joursSelected: [],
      selectedService: null,
      options: [
        { name: "Lundi", code: "Lundi" },
        { name: "Mardi", code: "Mardi" },
        { name: "Mercredi", code: "Mercredi" },
        { name: "Jeudi", code: "Jeudi" },
        { name: "Vendredi", code: "Vendredi" },
        { name: "Samedi", code: "Samedi" },
        { name: "Dimanche", code: "Dimanche" }
      ],
      horairesSelected: [],
      optionsHoraires: [
        { name: "06h00", code: "06h00" },
        { name: "08h00", code: "08h00" },
        { name: "08h30", code: "08h30" },
        { name: "09h00", code: "09h00" },
        { name: "10h45", code: "10h45" },
        { name: "11h15", code: "11h15" },
        { name: "12h00", code: "12h00" },
        { name: "13h30", code: "13h30" },
        { name: "16h15", code: "16h15" },
        { name: "19h00", code: "19h00" }
      ]
    };
  },
  mounted() {
    this.mesinfos = this.$store.getters.getUser;
    this.isVolant = this.$store.getters.isVolant;
    this.init();
  },
  computed: {
    filteredServices() {
      let filteredServices = this.services;
      // 1er filtre sur les jours
      if (this.joursSelected.length > 0) {
        filteredServices = filteredServices.filter(service => {
          return this.joursSelected.some(
            jour =>
              jour.code.toLowerCase() ===
              service.dateConverted.format("dddd").toLowerCase()
          );
        });
      }
      // 2nd filtre sur les horaires
      if (this.horairesSelected.length > 0) {

        filteredServices = filteredServices.filter(service => {
          return this.horairesSelected.some(
            heure =>
              heure.code.toLowerCase() ===
              service.dateConverted.format("HH:mm").toLowerCase()
          );
        });
      }
      filteredServices.sort(function(a,b) {
        return new Date(a.startDatetime) - new Date(b.startDatetime);
      });
      const monthName = item =>
        this.$moment(item.startDatetime).format("dddd Do MMMM  YYYY");
      return _.groupBy(filteredServices, monthName);
    },
    ...authComputed
  },
  methods: {
    init: async function() {
      try {
         this.loadingAPI = true;
        // Récupération de tous les services à venir pour les inscriptions
        let response = await shiftServices.fetchOpenShifts();
        this.services = response.data.shiftTickets;
        this.services.forEach(element => {
          let newDate = this.$moment.utc(element.startDatetime).toDate();
          element.dateConverted = this.$moment(newDate).local();
        });

        if(this.$moment().hours()>18)
        this.services = this.services.filter(elem => elem.dateConverted.isAfter(this.$moment().add(1,'days')) )

        // Récupération des horaires possibles
        let horaires = new Set(this.services.map((a) => a.dateConverted.format("HH:mm")).sort());
        this.optionsHoraires = []
        for (let horaire of horaires) this.optionsHoraires.push({ name: horaire, code: horaire },)


        // Récupération de tous les services où user inscrit
        response = await shiftServices.fetchMyShifts(this.mesinfos.id, this.$moment().subtract(1, 'year').format("YYYY-MM-DD"));
        this.messervices = response.data.shiftRegistrations;
        this.messervices.forEach(monService => {
          const newDate = this.$moment.utc(monService.dateBegin).toDate();
          monService.dateConverted = this.$moment(newDate).local();
          let serviceInscrit = this.services.find(service =>
            service.dateConverted.isSame(monService.dateConverted)
          );
          if (serviceInscrit) {
            serviceInscrit.registered = true;
          }
        });
      } catch (e) {
        this.errorAPI = true;
      } finally {
        this.loadingAPI = false;
      }
    },
    confirmInscription: function(service) {
      this.selectedService = service;
    },
    cancelInscription: function(){
      this.selectedService = null;
    },
    inscription: async function(service) {
      this.selectedService = null
      try {
        await shiftServices.subscribeOpenShifts(service.shift.id,service.id,service.dateBegin,this.mesinfos.id);
        this.message='Inscription en cours...';
        await this.init();
        this.$forceUpdate();
      } catch (error) {
        this.errorAPI = true;
      }
    }
  }
};

(function() {
  document.getElementById("select").selectize();
});
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag, .multiselect__option--highlight, .multiselect__option--highlight:after{
  background: #ff4200;
}
.multiselect__spinner:before,
.multiselect__spinner:after {
  border-color: #ff4200 transparent transparent;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #ff4200;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #525252cc;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #525252cc;
}
</style>
<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

input {
  display: none;
  visibility: hidden;
}

.date-title:first-letter, .selected-service:first-letter {
  text-transform: uppercase;
}

#expand {
  height: 0px;
  overflow: hidden;
  transition: height 1s;
  color: #fff;
}

#searchFilters {
  padding: 0px;
}

.bm--card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bm--card-equal-height .card-footer {
  margin-top: auto;
}


@media only screen and (max-width: 374px) {
  .box {
    padding: 10px 5px;
  }
}
.modal-card-head {
  text-align: center;
}
.modal-card-foot {
  display: flex;
  justify-content: flex-end;
}
</style>
